import Header from "../../Component/Header/Header";
import "./Terms.css";
import { useEffect } from "react";

const Main = () => {

    useEffect(() => {
        const handleAnchorClick = (event) => {
            event.preventDefault();
            const targetId = event.target.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offset = 80; 
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

                window.scrollTo({
                    top: targetPosition,
                    behavior: "smooth",
                });
            }
        };

        document.querySelectorAll(".sidebar a").forEach((anchor) => {
            anchor.addEventListener("click", handleAnchorClick);
        });

        return () => {
            document.querySelectorAll(".sidebar a").forEach((anchor) => {
                anchor.removeEventListener("click", handleAnchorClick);
            });
        };
    }, []);
    
    return (
        <main>
            <div className="header-container">
                <Header />
            </div>
            <div className="container">
                <div className="heading">
                    <h1>Terms and Conditions</h1>
                    <p>Last Updated: 10-30-2024</p>
                </div>
                <div className="content">
                    <div className="main-content">
                        <p>
                            Welcome to the Suprsync Platform (the "Platform"). By accessing 
                            or using the Platform, you agree to be bound by these terms and 
                            conditions (the "Terms") and our Privacy Policy, which is 
                            incorporated herein by reference
                        </p>

                        <h2 id="acceptance-of-terms">1. Acceptance of Terms</h2>
                        <p>
                            1.1. These Terms constitute a binding legal agreement between you 
                                and D-Spot Software, a company headquartered in Orlando, Florida 
                                ("D-Spot", "we", "us", or "our"), and govern your use of the Platform. 
                            1.2. By accessing or using the Platform, you acknowledge that you have 
                                read, understood, and agree to be bound by these Terms. If you do  
                                not agree tothese Terms, you are not authorized to use the Platform.
                        </p>

                        <h2 id="platform-access-and-use">2. Platform Access and Use</h2>
                        <p>
                            2.1. Account Registration: To access certain features of the Platform,
                                you may be required to register for an account. You agree to provide 
                                accurate and complete information during the registration process and to 
                                keep your account information up-to-date at all times. 
                            2.2. Authorized Use: You agree to use the Platform only for lawful 
                                purposes and in accordance with these Terms. You will not use the 
                                Platform in any way that could damage, disable, overburden, or impair 
                                the Platform or interfere with any other party's use and enjoyment of 
                                the Platform.
                            2.3. Restrictions: You agree not to (a) reproduce, duplicate, copy, sell, 
                                resell, or exploit any portion of the Platform; (b) use any robot,
                                spider, scraper, or other automated means to access the Platform; (c)
                                reverse engineer, decompile, or disassemble any software or other 
                                products or processes accessible through the Platform; or (d) 
                                circumvent, disable, or otherwise interfere with security-related 
                                features of the Platform.
                            2.4. Third-Party Integrations: The Platform may allow integration with 
                                third-party services or applications. Your use of such integrations is 
                                subject to the terms and conditions of the respective third-party 
                                providers, and D-Spot shall not be responsible for any issues or damages 
                                arising from your use of such third-party services.
                        </p>

                        <h2 id="proprietary-rights">3. Proprietary Rights</h2>
                        <p>
                            3.1. Ownership: The Platform, including all content, features, and functionality, is <br />
                                owned by D-Spot Software and its licensors and is protected by copyright, <br />
                                trademark, and other intellectual property laws.
                            3.2. License Grant: Subject to these Terms, D-Spot Software grants you a limited, <br />
                                non-exclusive, non-transferable license to access and use the Platform for <br />
                                your personal or internal business purposes.
                            3.3. Feedback: If you provide any feedback, suggestions, or ideas about the <br />
                                Platform ("Feedback"), you hereby assign all right, title, and interest in <br />
                                such Feedback to D-Spot Software and acknowledge that D-Spot Software may <br />
                                use the Feedback without restriction.
                        </p>

                        <h2 id="disclaimers">4. Disclaimers</h2>
                        <p>
                            4.1. The Platform is provided "as is" and "as available" without warranties <br />
                                of any kind, either express or implied, including but not limited to warranties <br />
                                of merchantability, fitness for a particular purpose, and non-infringement.
                            4.2. D-Spot Software does not warrant that the Platform will be uninterrupted, <br />
                                error-free, or free from viruses or other harmful components.
                            4.3. Your use of the Platform, including any content or information obtained <br />
                                through the Platform, is at your own risk. You are solely responsible for any <br />
                                damage to your computer system or loss of data that results from such use.
                        </p>

                        <h2 id="limitation-of-liability">5. Limitation of Liability</h2>
                        <p>
                            5.1. In no event shall D-Spot Software be liable for any indirect, special, <br />
                                incidental, or consequential damages arising out of or in connection with <br />
                                your use of the Platform, including but not limited to lost profits, <br />
                                business interruption, or loss of programs or data.
                            5.2. The total liability of D-Spot Software for any claims related to the <br />
                                Platform shall not exceed the greater of $100 or the amount you have <br />
                                paid to D-Spot Software for use of the Platform in the 12 months <br />
                                preceding the claim.
                        </p>

                        <h2 id="indemnification">6. Indemnification</h2>
                        <p>
                            You agree to indemnify and hold D-Spot Software harmless from and <br />
                            against any and all claims, liabilities, damages, losses, costs, expenses, <br />
                            or fees (including reasonable attorneys' fees) that arise from your use of <br />
                            the Platform, your violation of these Terms, or your violation of any rights <br />
                            of a third party.
                        </p>

                        <h2 id="termination">7. Termination</h2>
                        <p>
                            7.1. D-Spot Software reserves the right to suspend or terminate your access to the <br />
                                Platform at any time for any reason, including if D-Spot Software reasonably <br />
                                believes that you have violated these Terms.
                            7.2. Upon termination, you will immediately cease using the Platform and <br />
                                destroy any copies of any portion of the Platform in your possession.
                        </p>

                        <h2 id="governing-law-and-jurisdiction">8. Governing Law and Jurisdiction</h2>
                        <p>
                            These Terms shall be governed by and construed in accordance with the <br />
                            laws of the State of Florida, and any disputes arising out of or related to <br />
                            these Terms or the Platform shall be resolved in the state or federal courts <br />
                            located in Orange County, Florida.
                        </p>

                        <h2 id="miscellaneous">9. Miscellaneous</h2>
                        <p>
                            9.1. Entire Agreement: These Terms, together with the Privacy Policy, <br />
                                constitute the entire agreement between you and D-Spot Software <br />
                                regarding the Platform.
                            9.2. Severability: If any provision of these Terms is found to be <br />
                                unenforceable or invalid, the remaining provisions will continue in full <br />
                                force and effect.
                            9.3. No Waiver: D-Spot Software's failure to enforce any right or provision of <br />
                                these Terms shall not be considered a waiver of those rights.
                            9.4. Updates to Terms: D-Spot Software reserves the right to update these Terms <br />
                                from time to time. You will be notified of any changes and the updated Terms <br />
                                will be posted on the Platform. Your continued use of the Platform after <br />
                                such changes constitutes your acceptance of the revised Terms.  <br />
                        </p>
                        <p>
                            If you have any questions or concerns about this Privacy Policy or our privacy <br />
                            practices, please contact us at hello@suprsync.com.
                        </p>
                    </div>

                    <div className="sidebar">
                        <ul>
                            <li><a href="#acceptance-of-terms">Acceptance of Terms</a></li>
                            <li><a href="#platform-access-and-use">Platform Access and Use</a></li>
                            <li><a href="#proprietary-rights">Proprietary Rights</a></li>
                            <li><a href="#disclaimers">Disclaimers</a></li>
                            <li><a href="#limitation-of-liability">Limitation of Liability</a></li>
                            <li><a href="#indemnification">Indemnification</a></li>
                            <li><a href="#termination">Termination</a></li>
                            <li><a href="#governing-law-and-jurisdiction">Governing Law and Jurisdiction</a></li>
                            <li><a href="#miscellaneous">Miscellaneous</a></li>
                            <li><a href="#data-retention">Data Retention</a></li>
                            <li><a href="#security">Security</a></li>
                            <li><a href="#changes-to-policy">Changes to This Privacy Policy</a></li>
                            <li><a href="#contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Main;
