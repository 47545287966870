import { mobileImg, appleLogo, googlePlay } from "../../Assets";
import Container from "../../Component/Container";

const MobileExp = () => {
  return (
    <Container className="!py-[60px] mobilebackground">
      <div className="flex flex-row md:flex-col items-center gap-10">
        <div className="flex flex-col gap-[31px] w-[70%] md:w-full">
          <div className="flex flex-col gap-3">
            <p className="text-center rounded-[20px] justify-center items-center p-2.5 bg-FEE uppercase text-14 sm:text-[12px] font-medium w-[160px] sm:w-[120px]">
              free download
            </p>
            <p className="text-[50px] font-semibold leading-[64px] sm:text-[24px] sm:leading-[34px]">
              Experience our Service on Mobile.
            </p>
            <p className="text-[24px] text-[#606060] sm:text-[14px] sm:leading-[24px]">
              Our service can be experienced seamlessly on your mobile device.
            </p>
          </div>

          <div className="flex flex-row justify-center items-center gap-4 w-full sm:w-auto">
            <a
              href="#"
              className="flex items-center gap-3 bg-black pr-12 pl-6 py-4 sm:pr-2 sm:pl-4 sm:py-3 sm:gap-0 rounded-full hover:opacity-90 transition-all"
            >
              <img
                src={appleLogo}
                alt="Apple Logo"
                className="w-[32px] h-[32px] sm:w-[24px] sm:h-[24px]"
              />
              <div className="flex flex-col">
                <span className="text-[12px] sm:text-[10px] text-white font-thin">
                  DOWNLOAD ON
                </span>
                <span className="text-[18px] sm:text-[12px] text-white font-semibold">
                  App Store
                </span>
              </div>
            </a>

            <a
              href="#"
              className="flex items-center gap-3 bg-black pr-12 pl-6 py-4 sm:pr-3 sm:pl-4 sm:py-3 sm:gap-0 rounded-full hover:opacity-90 transition-all"
            >
              <img
                src={googlePlay}
                alt="Google Play Logo"
                className="w-[32px] h-[32px] sm:w-[24px] sm:h-[24px]"
              />
              <div className="flex flex-col">
                <span className="text-[12px] sm:text-[10px] text-white font-thin">
                  GET IT ON
                </span>
                <span className="text-[18px] sm:text-[12px] text-white font-semibold">
                  Google Play
                </span>
              </div>
            </a>
          </div>
        </div>

        <div className="sm:h-auto">
          <img
            src={mobileImg}
            className="rounded-lg w-full h-auto"
            alt="mobile"
          />
        </div>
      </div>
    </Container>
  );
};

export default MobileExp;
