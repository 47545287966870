import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from "./Screens/Home/Home";
import Pricing from "./Screens/Pricing/Pricing";
import ContactUs from "./Screens/Contact/ContactUs";
import About from "./Screens/About/About";
import Features from "./Screens/Features/Features";
import UseCases from "./Screens/UseCases/UseCases";
import PrivacyPolicy from "./Screens/PrivacyPolicy/PrivacyPolicy";
import Terms from "./Screens/Terms/Terms";
import NotFound from "./Screens/NotFound";
import ScrollToTop from "./Component/Nav/ScrollToTop";

const App = () => {
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-65Y9L5QW0J";
    document.head.appendChild(script);

    // Initialize Google Analytics
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-65Y9L5QW0J');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Features />} />
          <Route path="/use-cases" element={<UseCases />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
