import Layout from "../../Component/Layout";
import Main from "./main";

const PrivacyPolicy = () => {
    return (
        <Layout withHeader={false} withFooter={true} hideBanner={true}>
            <Main />
        </Layout>
    )
}

export default PrivacyPolicy;