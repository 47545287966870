import Layout from "../../Component/Layout";
import Main from "./Main";



const ContactUs = () => {
    return (
        <Layout withFooter={false}>
            <Main />

        </Layout>
    )
}


export default ContactUs;