import Header from "../../Component/Header/Header";
import "./PrivacyPolicy.css";
import { useEffect } from "react";

const Main = () => {

    useEffect(() => {
        const handleAnchorClick = (event) => {
            event.preventDefault();
            const targetId = event.target.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offset = 80; // Adjust for fixed header
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

                window.scrollTo({
                    top: targetPosition,
                    behavior: "smooth",
                });
            }
        };

        document.querySelectorAll(".sidebar a").forEach((anchor) => {
            anchor.addEventListener("click", handleAnchorClick);
        });

        return () => {
            document.querySelectorAll(".sidebar a").forEach((anchor) => {
                anchor.removeEventListener("click", handleAnchorClick);
            });
        };
    }, []);
    
    return (
        <main className="privacy-main">
            <div className="privacy-header-container">
                <Header />
            </div>
            <div className="privacy-container">
                <div className="privacy-heading">
                    <h1>Privacy Policy</h1>
                    <p>Last Updated: 10-30-2024</p>
                </div>
                <div className="privacy-content">
                    <div className="privacy-main-content">
                        <p>
                            This Privacy Policy describes how D-SPOT Software("we", "us", or "our") collects, <br />
                            uses, and shares information about you when you use our Suprsync platform <br />
                            (the "Platform").
                        </p>

                        <h2 id="information-we-collect">Information We Collect</h2>
                        <p>
                            <b>- Account Information:</b> When you create an account, we collect your name, email <br />
                            address, job title, company information, and any other information you provide. <br />
                            <b>- Usage Information:</b> We collect information about how you use the Platform, such <br />
                            as the actions you take, the pages you visit, the files you upload or download, and <br />
                            your interactions with any AI-powered features. <br />
                            <b>- Device Information:</b> We collect information about the device you use to access <br />
                            the Platform, such as the hardware model, operating system, IP address, and <br />
                            location data. <br />
                            <b>- Communications:</b> If you contact our support team or communicate with us in any <br />
                            way, we will collect the information you provide in those communications. <br />
                        </p>

                        <h2 id="how-we-use-your-information"><strong>How We Use Your Information</strong></h2>
                        <p>
                            <b>- To provide and improve the Platform:</b> We use your information to operate, <br />
                            maintain, and improve the Platform, including any AI-powered features or <br />
                            functionality. <br />
                            <b>- For personalization:</b> We may use your information to personalize your experience <br />
                            on the Platform, such as by providing customized recommendations or AI- <br />
                            generated insights. <br />
                            <b>- For security and legal purposes:</b> We use your information to protect our rights and <br />
                            the rights of others, and to comply with legal requirements. <br />
                            <b>- For communication:</b> We may use your information to communicate with you <br />
                            about the Platform, such as to send you updates, respond to your inquiries, or <br />
                            notify you of any changes to this Privacy Policy. <br />
                            <b>- For product development:</b> We may use your information, including any feedback <br />
                            or suggestions you provide, to develop new features, products, or services. <br />
                        </p>

                        <h2 id="sharing-your-information">Sharing Your Information</h2>
                        <p>
                            <b>- Within our company:</b> We may share your information within our company for the <br />
                            purposes described above. <br />
                            <b>- With service providers:</b> We may share your information with third-party service <br />
                            providers that help us operate the Platform, including any providers of AI-powered <br />
                            services or technologies. <br />
                            <b>- With third parties:</b> We may share your information with third parties, such as <br />
                            business partners or industry organizations, if we believe it is necessary to comply <br />
                            with a legal obligation, protect our rights or the rights of others, or for other <br />
                            legitimate business purposes. <br />
                            <b>- As required by law:</b> We may share your information if we are required to do <br />
                            so by law or legal process, such as to comply with a subpoena or court order. <br />
                        </p>

                        <h2 id="ai-powered-features">AI-Powered Features and Functionality</h2>
                        <p>
                            Our Platform includes AI-powered features and functionality that use your information <br />
                            to provide you with personalized recommendations, insights, and other services. <br />
                            These features are designed to help you make the most of the Platform and to <br />
                            enhance your experience.
                        </p>

                        <h2 id="your-choices">Your Choices</h2>
                        <p>
                            - You can access, update, or delete your account information at any time by logging <br />
                            into your account. <br />
                            - You can choose not to provide certain information, but this may limit your ability <br />
                            to use the Platform. <br />
                            - You can opt out of receiving certain communications from us by following the <br />
                            unsubscribe instructions in those communications. <br />
                            - You can request that we delete or anonymize your personal information at any time, <br />
                            subject to certain legal and operational exceptions. <br />
                        </p>

                        <h2 id="data-retention">Data Retention</h2>
                        <p>
                            We will retain your information for as long as your account is active or as needed to <br />
                            provide you the Platform. We may also retain and use your information as necessary to <br />
                            comply with our legal obligations, resolve disputes, and enforce our agreements. <br />
                            We will delete or anonymize your information when it is no longer necessary for these <br />
                            purposes.
                        </p>

                        <h2 id="security">Security</h2>
                        <p>
                            We use reasonable measures, including administrative, technical, and physical safeguards, <br />
                            to protect your information from unauthorized access, use, or disclosure. However, no <br />
                            method of transmission over the internet or method of electronic storage is 100% <br />
                            secure, so we cannot guarantee absolute security.
                        </p>

                        <h2 id="changes-to-policy">Changes to This Privacy Policy</h2>
                        <p>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by <br />
                            posting the new Privacy Policy on the Platform and updating the "Last Updated" date above. <br />
                            We encourage you to review this Privacy Policy periodically to stay informed about our <br />
                            privacy practices.      
                        </p>

                        <h2 id="contact-us">Contact Us</h2>
                        <p>
                            If you have any questions or concerns about this Privacy Policy or our privacy practices, <br />
                            please contact us at hello@suprsync.com.
                        </p>
                    </div>

                    <div className="privacy-sidebar">
                        <ul>
                            <li><a href="#information-we-collect">Information We Collect</a></li>
                            <li><a href="#how-we-use-your-information">How We Use Your Information</a></li>
                            <li><a href="#sharing-your-information">Sharing Your Information</a></li>
                            <li><a href="#ai-powered-features">AI-Powered Features and Functionality</a></li>
                            <li><a href="#your-choices">Your Choices</a></li>
                            <li><a href="#data-retention">Data Retention</a></li>
                            <li><a href="#security">Security</a></li>
                            <li><a href="#changes-to-policy">Changes to This Privacy Policy</a></li>
                            <li><a href="#contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Main;
